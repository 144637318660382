import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { BasicLayout, BottomSection, Button, LinkHandle, LoadingBox } from '..';
import Heading1 from './Heading1';
import color from '../colors';
import { media } from '../../utils/Helper';

interface ownProps {
  BreadTreeElements: { breadText: string; url: string }[];
  metaDescription: string;
  title: string;
  subTitle: string;
  heroImgURL: string;
  eyeCatchId: number;
  ogLink: string;
  bottomSectionText: string;
  bottomSectionColor: string;
  bottomButtonText: string;
  bottomButtonLink?: string;
  bottomButtonSize: string;
  bottomButtonClass?: string;
  pageName?: string;
}
interface ownState {
  guid: {
    rendered: string;
  };
}
const FVDiv = styled.div`
  position: relative;
`;
const BgLayerDiv = styled.div`
  height: 300px;
  ${media.tablet`height: 160px;`}
  &.bgImage {
    background: url(${({ theme }) => theme.bgImg}) no-repeat center;
    background-size: cover;
    background-position: 0px 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 0;
    overflow: hidden;
  }
  &.bgImage:before {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
  }
  &.bgImage.defaultImage:before {
    background: linear-gradient(120deg, rgb(62, 170, 221), rgb(55, 152, 196));
  }
  &.bgImage:after {
    background: inherit;
    filter: blur(3px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    content: ' ';
  }
`;
const TextDiv = styled.div`
  text-align: center;
  padding: 0px 20px;
  margin: 0 auto;
  color: #fff;
`;
const BreadcrumbDiv = styled.div`
  border-bottom: solid 1px ${color.lightGray};
  padding: 1.6rem 0;
  margin-bottom: 4rem;
  & a {
    display: inline-block;
  }
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
  .bread-item {
    line-height: 1.5;
    margin-right: 0.8rem;
    ${media.tablet`font-size: 0.8rem;margin-top: 0.2rem;
    margin-bottom: 0.2rem;`}
  }
  .bread-item:last-child .bread-arrow {
    display: none;
  }
`;
class PageBaseLayout extends Component<ownProps, ownState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      guid: { rendered: '' },
    };
  }
  createBreadTreeDom = (
    breadTreeElements: { breadText: string; url: string }[],
  ) => {
    const returnDom: JSX.Element[] = [];
    let tempUrl = '';
    breadTreeElements.map((x, index) => {
      tempUrl += `/${x.url}`;

      return returnDom.push(
        <div key={index} className="bread-item">
          <LinkHandle to={tempUrl}>{x.breadText}</LinkHandle>
          <span className="bread-arrow">
            {` > `}
          </span>
        </div>,
      );
    });
    return returnDom;
  };

  render() {
    const metaData = {
      title: this.props.title,
      metaDescription: this.props.metaDescription,
      eyeCatchId: this.props.eyeCatchId,
      ogLink: this.props.ogLink,
    };
    return (
      <BasicLayout
        metaData={metaData}
        pageName={this.props.pageName ? this.props.pageName : ''}
      >
        <BgLayerDiv
          className={`bgImage ${this.props.heroImgURL ? '' : 'defaultImage'}`}
          theme={{ bgImg: this.props.heroImgURL }}
        >
          <FVDiv>
            <TextDiv>
              {metaData.title ? (
                <Fragment>
                  <Heading1>{this.props.title}</Heading1>
                  <div>{this.props.subTitle}</div>
                </Fragment>
              ) : (
                <LoadingBox />
              )}
            </TextDiv>
          </FVDiv>
        </BgLayerDiv>
        <BreadcrumbDiv className="flex">
          <div className="bread-item">
            <LinkHandle to={'/'}>ホーム</LinkHandle>
            <span className="bread-arrow">
              {` > `}
            </span>
          </div>

          {this.createBreadTreeDom(this.props.BreadTreeElements)}
        </BreadcrumbDiv>

        {metaData.title ? this.props.children : <LoadingBox />}

        <BottomSection
          text={this.props.bottomSectionText}
          backgroundColor={this.props.bottomSectionColor}
        >
          <Button
            theme={{ main: this.props.bottomButtonSize }}
            className={this.props.bottomButtonClass}
          >
            <LinkHandle
              to={
                this.props.bottomButtonLink
                  ? this.props.bottomButtonLink
                  : '/contact'
              }
            >
              {this.props.bottomButtonText}
            </LinkHandle>
          </Button>
        </BottomSection>
      </BasicLayout>
    );
  }
}
export default PageBaseLayout;
