import React, { Component } from 'react';

import HelpfulInfoChildPageBase from '../HelpfulInfoChildPageBase';

const PRE_TRAVEL_PROCEDURES_ID = 3040;

class PreTravelProceduresInJapan extends Component {
  render() {
    return <HelpfulInfoChildPageBase pageID={PRE_TRAVEL_PROCEDURES_ID} />;
  }
}

export default PreTravelProceduresInJapan;
