import React from 'react';
import styled from 'styled-components';
import LoadingSvg from '../../assets/images/loading.svg';

const LoadingDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingBox = () => {
  return (
    <LoadingDiv>
      <img src={LoadingSvg} alt="loading" />
    </LoadingDiv>
  );
};
export default LoadingBox;
