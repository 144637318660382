import React, { Component } from 'react';

import { PageBaseLayout } from '../..';
import { getData, BreadTreeElement } from '../../../utils/PageApi';
import { buttonText } from '../../../constants/buttonText';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import HelpfulInfoContent from '../HelpfulInfo/HelpfulInfoContent';

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    ourServiceApply1Title: string;
    ourServiceApply1Text: string;
    ourServiceApply1Img: string;
    ourServiceApply1ButtonText: string;
    ourServiceApply1ButtonLink: string;
    ourServiceApply2Title: string;
    ourServiceApply2Text: string;
    ourServiceApply2Img: string;
    ourServiceApply2ButtonText: string;
    ourServiceApply2ButtonLink: string;
    ourServiceApply3Title: string;
    ourServiceApply3Img: string;
    ourServiceApply3Text: string;
    ourServiceApply3ButtonText: string;
    ourServiceApply3ButtonLink: string;
    LifeUsefulInfoApply1Title: string;
    LifeUsefulInfoApply1Img: string;
    LifeUsefulInfoApply1Text: string;
    LifeUsefulInfoApply1ButtonText: string;
    LifeUsefulInfoApply1ButtonLink: string;
    LifeUsefulInfoApply2Title: string;
    LifeUsefulInfoApply2Img: string;
    LifeUsefulInfoApply2Text: string;
    LifeUsefulInfoApply2ButtonText: string;
    LifeUsefulInfoApply2ButtonLink: string;
    visaApply1Title: string;
    visaApply1Img: string;
    visaApply1Text: string;
    visaApply1ButtonText: string;
    visaApply1ButtonLink: string;
    visaApply2Title: string;
    visaApply2Img: string;
    visaApply2Text: string;
    visaApply2ButtonText: string;
    visaApply2ButtonLink: string;
    visaApply3Title: string;
    visaApply3Text: string;
    visaApply3Img: string;
    visaApply3ButtonText: string;
    visaApply3ButtonLink: string;
  };
  slug: string;
  parent: number;
};
interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class HelpfulInfo extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          ourServiceApply1Title: '',
          ourServiceApply1Text: '',
          ourServiceApply1Img: '',
          ourServiceApply1ButtonText: '',
          ourServiceApply1ButtonLink: '',
          ourServiceApply2Title: '',
          ourServiceApply2Text: '',
          ourServiceApply2Img: '',
          ourServiceApply2ButtonText: '',
          ourServiceApply2ButtonLink: '',
          ourServiceApply3Title: '',
          ourServiceApply3Img: '',
          ourServiceApply3Text: '',
          ourServiceApply3ButtonText: '',
          ourServiceApply3ButtonLink: '',
          LifeUsefulInfoApply1Title: '',
          LifeUsefulInfoApply1Img: '',
          LifeUsefulInfoApply1Text: '',
          LifeUsefulInfoApply1ButtonText: '',
          LifeUsefulInfoApply1ButtonLink: '',
          LifeUsefulInfoApply2Title: '',
          LifeUsefulInfoApply2Img: '',
          LifeUsefulInfoApply2Text: '',
          LifeUsefulInfoApply2ButtonText: '',
          LifeUsefulInfoApply2ButtonLink: '',
          visaApply1Title: '',
          visaApply1Img: '',
          visaApply1Text: '',
          visaApply1ButtonText: '',
          visaApply1ButtonLink: '',
          visaApply2Title: '',
          visaApply2Img: '',
          visaApply2Text: '',
          visaApply2ButtonText: '',
          visaApply2ButtonLink: '',
          visaApply3Title: '',
          visaApply3Text: '',
          visaApply3Img: '',
          visaApply3ButtonText: '',
          visaApply3ButtonLink: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  async createData() {
    let pageId = 1187;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }

  componentDidMount() {
    this.createData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;

    const serviceApplyData = [
      {
        title: dataAcf.ourServiceApply1Title,
        img: dataAcf.ourServiceApply1Img,
        text: dataAcf.ourServiceApply1Text,
        link: dataAcf.ourServiceApply1ButtonLink,
        buttonText: dataAcf.ourServiceApply1ButtonText,
      },
      {
        title: dataAcf.ourServiceApply2Title,
        img: dataAcf.ourServiceApply2Img,
        text: dataAcf.ourServiceApply2Text,
        link: dataAcf.ourServiceApply2ButtonLink,
        buttonText: dataAcf.ourServiceApply2ButtonText,
      },
      {
        title: dataAcf.ourServiceApply3Title,
        img: dataAcf.ourServiceApply3Img,
        text: dataAcf.ourServiceApply3Text,
        link: dataAcf.ourServiceApply3ButtonLink,
        buttonText: dataAcf.ourServiceApply3ButtonText,
      },
    ];
    const lifeUsefulInfoApplyData = [
      {
        title: dataAcf.LifeUsefulInfoApply1Title,
        img: dataAcf.LifeUsefulInfoApply1Img,
        text: dataAcf.LifeUsefulInfoApply1Text,
        link: dataAcf.LifeUsefulInfoApply1ButtonLink,
        buttonText: dataAcf.LifeUsefulInfoApply1ButtonText,
      },
      {
        title: dataAcf.LifeUsefulInfoApply2Title,
        img: dataAcf.LifeUsefulInfoApply2Img,
        text: dataAcf.LifeUsefulInfoApply2Text,
        link: dataAcf.LifeUsefulInfoApply2ButtonLink,
        buttonText: dataAcf.LifeUsefulInfoApply2ButtonText,
      },
    ];

    const visaApply1 = [
      {
        title: dataAcf.visaApply1Title,
        img: dataAcf.visaApply1Img,
        text: dataAcf.visaApply1Text,
        link: dataAcf.visaApply1ButtonLink,
        buttonText: dataAcf.visaApply1ButtonText,
      },
      {
        title: dataAcf.visaApply2Title,
        img: dataAcf.visaApply2Img,
        text: dataAcf.visaApply2Text,
        link: dataAcf.visaApply2ButtonLink,
        buttonText: dataAcf.visaApply2ButtonText,
      },
    ];
    const visaApply2 = [
      {
        title: dataAcf.visaApply3Title,
        img: dataAcf.visaApply3Img,
        text: dataAcf.visaApply3Text,
        link: dataAcf.visaApply3ButtonLink,
        buttonText: dataAcf.visaApply3ButtonText,
      },
    ];

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={'#fff'}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <HelpfulInfoContent
          serviceApplyData={serviceApplyData}
          lifeUsefulInfoApplyData={lifeUsefulInfoApplyData}
          visaApply1={visaApply1}
          visaApply2={visaApply2}
        />
      </PageBaseLayout>
    );
  }
}
export default HelpfulInfo;
