import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Media from 'react-media';

import color from '../../colors';
import FooterItem from './FooterItem';
import { media } from '../../../utils/Helper';
import { LinkHandle } from '../../';
import { getFooterSubMenu } from '../../../utils/MenuApi';
import FooterCompanyInfo from './FooterCompanyInfo';

const BoxDiv = styled.div`
  margin: auto;
  max-width: 1200px;
`;
const FooterBox = styled.footer`
  background-color: ${color.footerBackGroudn};
  color: #fff;
  padding: 8.3rem;
  ${media.tablet`padding: 1.8rem`}
  // 100vw = 画面フルサイズ　100% = 対象コンテンツフルサイズ　
  // -1　：ネガティブマージンを使う事で幅が広がる為、マイナス値を掛ける。
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
const OtherItem = styled.div`
  margin: 0 1.4rem;
  &.footer-sub-menu {
    margin: 0.6rem 1.4rem;
    ${media.tablet`margin: 0.6rem 0rem;`}
  }
  & a {
    color: #fff;
  }
  & a:hover {
    color: ${color.primary};
  }
`;
const CopyRight = styled.div`
  line-height: 2;
  width: 50%;
`;

interface OwnProps {}
interface OwnState {
  menus: {
    items: [{ title: string; url: string }];
  };
}

class Footer extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      menus: {
        items: [{ title: '', url: '' }],
      },
    };
  }

  async getMenuData() {
    const menuData = await getFooterSubMenu();

    if (menuData && menuData.items) {
      this.setState({
        menus: menuData,
      });
    }
  }

  componentDidMount() {
    this.getMenuData();
  }

  render() {
    let menus = this.state.menus.items;

    const footerSubMenuDom = menus.map((item, index) => (
      <OtherItem key={index} className="footer-sub-menu">
        <LinkHandle to={item.url}>{item.title}</LinkHandle>
      </OtherItem>
    ));

    const copyRight = (
      <CopyRight>
        © {new Date().getFullYear()}
        {` `}COS{` `}Educational{` `}Consulting{` `}Inc.
      </CopyRight>
    );

    const desktopDom = (
      <BoxDiv>
        <FooterCompanyInfo />
        <FlexBox>
          {copyRight}
          <FlexBox>{footerSubMenuDom}</FlexBox>
        </FlexBox>
      </BoxDiv>
    );

    const tabletDom = (
      <Fragment>
        <div>{footerSubMenuDom}</div>
        <FooterCompanyInfo />
        {copyRight}
      </Fragment>
    );

    return (
      <FooterBox>
        <FooterItem />
        <Media query="(max-width: 1024px)">
          {matches => (matches ? tabletDom : desktopDom)}
        </Media>
      </FooterBox>
    );
  }
}

export default Footer;
