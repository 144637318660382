import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Heading2,
  LinkHandle,
} from '../..';
import { NewsData } from '../../../type/newsData';
import { formatDate } from '../../../utils/dateFormatter';
import { media } from '../../../utils/Helper';
import { IP } from '../../../utils/IPadress';
import color from '../../colors';

const NewsContainer = styled.div`
  padding: 8.4rem 0rem 8rem;
  background-color: ${color.lightGray};
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
`;

const NewsBox = styled.div`
  margin: 0px 8.3rem;
  ${media.tablet`margin: 0px 1.8rem`};
`;

const NewsItemBox = styled.div`
  border-bottom: solid 1px ${color.gray};
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
  ${media.desktop`display: flex;
  justify-content: flex-start;`}
`;

const NewsDay = styled.span`
  padding-right: 5.9rem;
  display: inline-block;
`;

const NewsText = styled.div`
  font-weight: bold;
`;

const More = styled.div`
  text-align: right;
  margin-top: 2.4rem;
  & a {
    color: ${color.blue};
  }
  & a:hover {
    color: ${color.primary};
  }
`;

interface OwnProps {
  limit?: number;
}

interface OwnState {
  news: NewsData[];
}

class ExcerptNews extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      news: [],
    };
  }

  async componentDidMount() {
    const { limit = 100 } = this.props;

    let dataURL = `${IP}/wp-json/wp/v2/news?orderby=modified&per_page=${limit}`;
    const response = await fetch(dataURL);
    const news = await response.json();
    this.setState({ news });
  }

  render() {
    const { news } = this.state;

    return (
      <NewsContainer>
        <Heading2>最新情報</Heading2>
        <NewsBox>
          {news.map((item, index) => (
            <NewsItemBox key={index}>
              <NewsDay>{formatDate(item.date)}</NewsDay>
              <LinkHandle to={`/news-list/${item.id}`}>
                <NewsText
                  dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}
                />
              </LinkHandle>
            </NewsItemBox>
          ))}

          <More>
            <LinkHandle to={'/news-list'}>一覧を見る</LinkHandle>
          </More>
        </NewsBox>
      </NewsContainer>
    );
  }
}

export default ExcerptNews;
