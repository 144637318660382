import React, { Component } from 'react';
import {
  PageBaseLayout,
  PageDescription,
} from '../../';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import { buttonText } from '../../../constants/buttonText';
import { NewsData } from '../../../type/newsData';
import { IP } from '../../../utils/IPadress';
import {
  BreadTreeElement,
  getData,
} from '../../../utils/PageApi';
import color from '../../colors';
import NewsListContent from './NewsListContent';

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    title1: string;
    description: string;
  };
  slug: string;
  parent: number;
};

interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
  newsData: NewsData[];
}

class NewsList extends Component<{}, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          title1: '',
          description: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
      newsData: [],
    };
  }

  async createData() {
    let pageId = 1472;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }

  async componentDidMount() {
    await this.createData();
    const newsDataURL = `${IP}/wp-json/wp/v2/news/?orderby=modified`;

    const response = await fetch(newsDataURL);
    const newsData = await response.json();
    this.setState({ newsData });
  }

  createListDom = (news: NewsData[]) => {
    const returnDom: JSX.Element[] = [];

    news.map((news, index) =>
      returnDom.push(
        <NewsListContent key={index} news={news} />,
      ),
    );
    return returnDom;
  };

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;
    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={color.lightGray}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <PageDescription>
          <div dangerouslySetInnerHTML={{ __html: dataAcf.pageDescription }} />
        </PageDescription>

        {this.createListDom(this.state.newsData)}
      </PageBaseLayout>
    );
  }
}

export default NewsList;
