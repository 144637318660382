import classnames from 'classnames';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/Helper';
import color from '../../colors';
import font from '../../fonts';

const BoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;
  width: 288px;
  position: relative;
  ${media.tablet`width: 100%;
  margin-right:0rem;
  margin-left:0rem;`}
  overflow: hidden;

  img {
    margin-bottom: 0;
    object-fit: cover;
  }

  &.about-item {
    img {
      width: auto;
      height: 100%;
    }
  }

  ${media.tablet`
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
  `}
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:before {
    position: absolute;
    content: ${({ theme }) => `${theme.flowStep}`};
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #ff0000;
  }
`;

const UpperDiv = styled.div`
  position: relative;
`;

const TitleDiv = styled.div`
  position: absolute;
  bottom: 8px;
  width: 100%;
  opacity: 0.8;
  text-align: center;
  color: #fff;
  background: ${color.buttonLightBlue};
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 1;

  &.onlyTitle {
    padding-top: 47px;
    padding-bottom: 47px;
  }

  & span {
    padding-top: 1.6rem;
    display: block;
    margin: 0 auto;
    width: 4rem;
    border-bottom: solid 1px #fff;
  }
`;

const Title = styled.div`
  font-family: ${font.jpMedium};
  font-size: 2rem;
`;

const LowerDiv = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const NumDiv = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  color: #fff;
  background: ${color.buttonLightBlue};
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
`;

type OwnProps = {
  title?: string;
  text?: string;
  img?: string;
  numOfStep: number;
}

const FlowStepBox = (props: OwnProps) => {
  const {
    title,
    text,
    img,
    numOfStep,
  } = props;

  return (
    <BoxDiv>
      <UpperDiv>
        <NumDiv>{numOfStep}</NumDiv>
        <div>
          <img
            src={img}
            alt={title}
            height="400px"
            width=""
          />
          <TitleDiv className={classnames({ onlyTitle: !title })}>
            <Fragment>
              <Title>{title}</Title>
            </Fragment>
          </TitleDiv>
        </div>
      </UpperDiv>
      <LowerDiv dangerouslySetInnerHTML={{ __html: text || '' }} />
    </BoxDiv>
  );
};

export default FlowStepBox;
