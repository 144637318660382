import React from 'react';
import styled from 'styled-components';
import { Heading3 } from '../..';
import { media } from '../../../utils/Helper';
import colors from '../../colors';

const BoxDiv = styled.div`
  background-color: #fff;
  display: flex;
  width: 30%;
  ${media.tablet`
  width: 100%;
  `}
  margin: 0 2.5%;
  padding: 6.4rem;
  ${media.tablet`
  padding: 3.2rem;
  `}
  flex-direction: column;

  &:first-child {
    margin-left: 0;
    ${media.tablet`margin: 3.2rem;`}
  }

  &:last-child {
    margin-right: 0;
    ${media.tablet`margin: 3.2rem;`}
  }

  ${media.tablet`
  flex-direction: column;
  `}
  h3 {
    margin: 0;
  }

  .contents {
    margin-top: 3.4rem;
  }

  .contents li:before {
    content: '・';
    color: ${colors.red};
    margin-left: -8px;
  }
`;

type OwnProps = {
  title?: string;
  content?: string;
}

const ServiceBox = (props: OwnProps) => {
  const { title = '', content = '' } = props;

  return (
    <BoxDiv>
      <Heading3>{title}</Heading3>
      <div
        className="contents"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </BoxDiv>
  );
};

export default ServiceBox;
