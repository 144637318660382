import React, { Component } from 'react';
import styled from 'styled-components';
import { MenuAltRight } from 'styled-icons/boxicons-regular';
import { elastic as Menu } from 'react-burger-menu';
import { styles } from './MobileMenuStyle';
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';

import Logo from '../../../assets/images/COS_Educational_Consulting_Inc_Logo_Jap.svg';
import color from '../../colors';
import { media } from '../../../utils/Helper';

const Head = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 0px;
    top: 10px;
  }
`;
const COSLogo = styled.img`
  max-width: 148.15px;
  margin-bottom: 2rem;
  height: 100%;
  ${media.tablet`height:25px;`}
`;
const FlexBox = styled.div`
  margin: 0 auto;
  maxwidth: 1366;
  padding: 1.45rem 1.0875rem;
  display: flex;
`;
const FlexEnd = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Icon = styled.div`
  width: 24px;
  color: ${color.blue};
`;

const Span = styled.span`
  padding-right: 1.5rem;
  a {
    text-decoration: underline;
  }
`;

interface OwnProps {}
interface OwnState {
  menuOpen: boolean;
}

class HeaderMobile extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      menuOpen: false,
    };
  }
  handleMenuClick = () => {
    this.setState({ menuOpen: false });
  };
  handleStateChange(state: any) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }
  render() {
    return (
      <Head>
        <Menu
          styles={styles}
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
          right={true}
          isOpen={this.state.menuOpen}
          onStateChange={state => this.handleStateChange(state)}
        >
          <MobileMenu
            handleMenuClick={() => {
              this.toggleMenu();
            }}
          />
        </Menu>
        <FlexBox>
          <Link to="/">
            <COSLogo src={Logo} className="" alt="logo" />
          </Link>
          <FlexEnd>
            <Span>
              <Link to="/contact">お問い合わせ</Link>
            </Span>
            <Icon>
              <MenuAltRight />
            </Icon>
          </FlexEnd>
        </FlexBox>
      </Head>
    );
  }
}
export default HeaderMobile;
