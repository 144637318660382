import React, { Component } from 'react';

import { PageBaseLayout, PageDescription } from '../..';
import { getData, BreadTreeElement } from '../../../utils/PageApi';
import { buttonText } from '../../../constants/buttonText';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import SupportContents from './SupportContents';

type PageDataType = {
  title: { rendered: string };
  featured_media: number;
  link: string;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    service1Title: string;
    service1Step1Title: string;
    service1Step1Text: string;
    service1Step1Img: string;
    service1Step2Title: string;
    service1Step2Text: string;
    service1Step2Img: string;
    service1Step3Title: string;
    service1Step3Text: string;
    service1Step3Img: string;
    service1Step4Title: string;
    service1Step4Text: string;
    service1Step4Img: string;
    service2Title: string;
    service2Step1Title: string;
    service2Step1Text: string;
    service2Step1Img: string;
    service2Step2Title: string;
    service2Step2Text: string;
    service2Step2Img: string;
    service2Step3Title: string;
    service2Step3Text: string;
    service2Step3Img: string;
    service2Step4Title: string;
    service2Step4Text: string;
    service2Step4Img: string;
    service3Title: string;
    service3Step1Title: string;
    service3Step1Text: string;
    service3Step1Img: string;
    service3Step2Title: string;
    service3Step2Text: string;
    service3Step2Img: string;
    service3Step3Title: string;
    service3Step3Text: string;
    service3Step3Img: string;
    service1Description: string;
    service1Color: string;
    service2Description: string;
    service2Color: string;
    service3Description: string;
    service3Color: string;
  };
  slug: string;
  parent: number;
};

interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class Support extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          service1Title: '',
          service1Step1Title: '',
          service1Step1Text: '',
          service1Step1Img: '',
          service1Step2Title: '',
          service1Step2Text: '',
          service1Step2Img: '',
          service1Step3Title: '',
          service1Step3Text: '',
          service1Step3Img: '',
          service1Step4Title: '',
          service1Step4Text: '',
          service1Step4Img: '',
          service2Title: '',
          service2Step1Title: '',
          service2Step1Text: '',
          service2Step1Img: '',
          service2Step2Title: '',
          service2Step2Text: '',
          service2Step2Img: '',
          service2Step3Title: '',
          service2Step3Text: '',
          service2Step3Img: '',
          service2Step4Title: '',
          service2Step4Text: '',
          service2Step4Img: '',
          service3Title: '',
          service3Step1Title: '',
          service3Step1Text: '',
          service3Step1Img: '',
          service3Step2Title: '',
          service3Step2Text: '',
          service3Step2Img: '',
          service3Step3Title: '',
          service3Step3Text: '',
          service3Step3Img: '',
          service1Description: '',
          service1Color: '',
          service2Description: '',
          service2Color: '',
          service3Description: '',
          service3Color: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  async createData() {
    let pageId = 876;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }

  componentDidMount() {
    this.createData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={'#fff'}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <PageDescription>
          <div dangerouslySetInnerHTML={{ __html: dataAcf.pageDescription }} />
        </PageDescription>
        <SupportContents
          step1Data={{
            serviceTitle: dataAcf.service1Title,
            serviceDescription: dataAcf.service1Description,
            color: dataAcf.service1Color,
            steps: [
              {
                title: dataAcf.service1Step1Title,
                text: dataAcf.service1Step1Text,
                img: dataAcf.service1Step1Img,
              },
              {
                title: dataAcf.service1Step2Title,
                text: dataAcf.service1Step2Text,
                img: dataAcf.service1Step2Img,
              },
              {
                title: dataAcf.service1Step3Title,
                text: dataAcf.service1Step3Text,
                img: dataAcf.service1Step3Img,
              },
              {
                title: dataAcf.service1Step4Title,
                text: dataAcf.service1Step4Text,
                img: dataAcf.service1Step4Img,
              },
            ],
          }}
          step2Data={{
            serviceTitle: dataAcf.service2Title,
            serviceDescription: dataAcf.service2Description,
            color: dataAcf.service2Color,
            steps: [
              {
                title: dataAcf.service2Step1Title,
                text: dataAcf.service2Step1Text,
                img: dataAcf.service2Step1Img,
              },
              {
                title: dataAcf.service2Step2Title,
                text: dataAcf.service2Step2Text,
                img: dataAcf.service2Step2Img,
              },
              {
                title: dataAcf.service2Step3Title,
                text: dataAcf.service2Step3Text,
                img: dataAcf.service2Step3Img,
              },
              {
                title: dataAcf.service2Step4Title,
                text: dataAcf.service2Step4Text,
                img: dataAcf.service2Step4Img,
              },
            ],
          }}
          step3Data={{
            serviceTitle: dataAcf.service3Title,
            serviceDescription: dataAcf.service3Description,
            color: dataAcf.service3Color,
            steps: [
              {
                title: dataAcf.service3Step1Title,
                text: dataAcf.service3Step1Text,
                img: dataAcf.service3Step1Img,
              },
              {
                title: dataAcf.service3Step2Title,
                text: dataAcf.service3Step2Text,
                img: dataAcf.service3Step2Img,
              },
              {
                title: dataAcf.service3Step3Title,
                text: dataAcf.service3Step3Text,
                img: dataAcf.service3Step3Img,
              },
            ],
          }}
        />
      </PageBaseLayout>
    );
  }
}
export default Support;
