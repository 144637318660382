import React, { Component, Fragment } from 'react';
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';

import {
  LandingPage,
  NewsList,
  News,
  NotFound,
  Program,
  ECE,
  IT,
  Nanny,
  Immigration,
  VisaCollection,
  VisaApply,
  School,
  Support,
  Price,
  About,
  Company,
  Staff,
  JobList,
  Job,
  HelpfulInfo,
  Insurance,
  Etransfer,
  EtaApply,
  StudentVisaApply,
  WhVisaApply,
  WhVisaApply2,
  Biometrics,
  PreTravelProceduresInJapan,
  Contact,
  TermsOfService,
  HomestayTermsOfService,
  PrivacyPolicy,
  EceSchool,
  ItSchool,
  VisaPrice,
  WebDevelopment,
} from '../';
import { RedirectUrl } from './Redirect';
import ReactGA from 'react-ga';
import getGaId from '../../constants/googleAnalytics';

ReactGA.initialize(getGaId(), {
  debug: process.env.NODE_ENV === 'development',
});
const ANALYTICS = (props: RouteComponentProps) => {
  ReactGA.pageview(
    props.location.pathname + props.location.search + props.location.hash,
  );
  return null;
};

class Container extends Component<RouteComponentProps> {
  render() {
    return (
      <Fragment>
        <ANALYTICS {...this.props} />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/news-list" component={NewsList} />
          <Route exact path="/news-list/:id" component={News} />
          <Route exact path="/program" component={Program} />
          <Route exact path="/program/ECE" component={ECE} />
          <Route exact path="/program/it" component={IT} />
          <Route exact path="/program/ecefromjapan" component={Nanny} />
          <Route exact path="/program/immigration" component={Immigration} />
          <Route
            exact
            path="/program/immigration/collection"
            component={VisaCollection}
          />
          <Route
            exact
            path="/program/immigration/visa-apply"
            component={VisaApply}
          />
          <Route exact path="/program/web-development" component={WebDevelopment} />
          <Route exact path="/school" component={School} />
          <Route exact path="/school/ece" component={EceSchool} />
          <Route exact path="/school/it" component={ItSchool} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/support/price" component={Price} />
          <Route exact path="/support/visa-price" component={VisaPrice} />
          <Route exact path="/about" component={About} />
          <Route exact path="/about/company" component={Company} />
          <Route exact path="/about/staff" component={Staff} />
          <Route exact path="/about/job-list" component={JobList} />
          <Route exact path="/about/job-list/:id" component={Job} />
          <Route exact path="/helpful-info" component={HelpfulInfo} />
          <Route exact path="/helpful-info/e-transfer" component={Etransfer} />
          <Route exact path="/helpful-info/insurance" component={Insurance} />
          <Route exact path="/helpful-info/eta-apply" component={EtaApply} />
          <Route
            exact
            path="/helpful-info/student-visa-apply"
            component={StudentVisaApply}
          />
          <Route
            exact
            path="/helpful-info/wh-visa-apply"
            component={WhVisaApply}
          />
          <Route
            exact
            path="/helpful-info/wh-visa-apply2"
            component={WhVisaApply2}
          />
          <Route exact path="/helpful-info/biometrics" component={Biometrics} />
          <Route exact path="/helpful-info/pre-travel-procedures-in-japan" component={PreTravelProceduresInJapan} />
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <Route
            exact
            path="/homestay-terms-of-service"
            component={HomestayTermsOfService}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/404" component={NotFound} />
          <Redirect from="*" to="" />
        </Switch>
        {RedirectUrl}
      </Fragment>
    );
  }
}
export default withRouter(Container);
