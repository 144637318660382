import React, {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import { buttonText } from '../../../constants/buttonText';
import { media } from '../../../utils/Helper';
import {
  BreadTreeElement,
  getData,
} from '../../../utils/PageApi';
import {
  Heading2,
  PageBaseLayout,
  PageDescription,
} from '../../index';

type PageDataType = {
  title: {
    rendered: string;
  };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    priceList1Title: string;
    priceList1Description: string;
    priceList1TableShortCode: string;
  };
  slug: string;
  parent: number;
};

type OwnState = {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

const BoxDiv = styled.div`
  padding: 0 8rem 8rem 8rem;
  ${media.tablet`padding: 0;`}
`;

const VisaPrice = (): JSX.Element => {
  const PAGE_SLUG = 'visa-price';
  const INITIAL_STATE: OwnState = {
    data: {
      title: { rendered: '' },
      link: '',
      featured_media: 0,
      acf: {
        metaDescription: '',
        heroImg: '',
        subtitle: '',
        pageDescription: '',
        priceList1Title: '',
        priceList1Description: '',
        priceList1TableShortCode: '',
      },
      slug: '',
      parent: 0,
    },
    breadTreeElements: [],
  };

  const [state, setState] = useState<OwnState>(INITIAL_STATE);

  useEffect(
    () => {
      async function initialize() {
        const state = await getData<PageDataType>(PAGE_SLUG);
        setState(state);
      }

      initialize();
    },
    [],
  );

  const { breadTreeElements, data } = state;
  const { acf, title } = data;

  return (
    <PageBaseLayout
      metaDescription={acf.metaDescription}
      BreadTreeElements={breadTreeElements}
      heroImgURL={acf.heroImg}
      title={title.rendered}
      subTitle={acf.subtitle}
      eyeCatchId={data.featured_media}
      ogLink={data.link}
      bottomSectionText={BottomSectionText.pattern1}
      bottomSectionColor={'#fff'}
      bottomButtonText={buttonText.freeConsulting}
      bottomButtonSize={'23.7rem'}
    >
      <PageDescription>
        <div dangerouslySetInnerHTML={{ __html: acf.pageDescription }} />
      </PageDescription>
      <BoxDiv>
        <Heading2>{acf.priceList1Title}</Heading2>
        <div dangerouslySetInnerHTML={{ __html: acf.priceList1Description }} />
      </BoxDiv>
    </PageBaseLayout>
  );
};

export {
  VisaPrice as default,
};
