import React, { Component, Fragment } from 'react';

import { PageBaseLayout, PageDescription, ProgramContainer } from '../..';
import { getData, BreadTreeElement } from '../../../utils/PageApi';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import { buttonText } from '../../../constants/buttonText';
import StepBox from './StepsSection';
import colors from '../../colors';

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    stepTitle: string;
    step1Title: string;
    step1color: string;
    step1Text: string;
    step1Img: { url: string };
    step2Title: string;
    step2color: string;
    step2Text: string;
    step2Img: { url: string };
    step3Title: string;
    step3color: string;
    step3Text: string;
    step3Img: { url: string };
  };
  slug: string;
  parent: number;
};

interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class Program extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          stepTitle: '',
          step1Title: '',
          step1color: '',
          step1Text: '',
          step1Img: { url: '' },
          step2Title: '',
          step2color: '',
          step2Text: '',
          step2Img: { url: '' },
          step3Title: '',
          step3color: '',
          step3Text: '',
          step3Img: { url: '' },
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  async createData() {
    let pageId = 236;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }

  componentDidMount() {
    this.createData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;

    return (
      <Fragment>
        <PageBaseLayout
          metaDescription={dataAcf.metaDescription}
          BreadTreeElements={this.state.breadTreeElements}
          heroImgURL={dataAcf.heroImg}
          title={title}
          subTitle={dataAcf.subtitle}
          eyeCatchId={data.featured_media}
          ogLink={data.link}
          bottomSectionText={BottomSectionText.pattern1}
          bottomSectionColor={colors.lightGray}
          bottomButtonText={buttonText.freeConsulting}
          bottomButtonSize={'23.7rem'}
        >
          <PageDescription>
            <div
              dangerouslySetInnerHTML={{
                __html: dataAcf.pageDescription,
              }}
            />
          </PageDescription>
          <StepBox
            mainTitle={dataAcf.stepTitle}
            step1Title={dataAcf.step1Title}
            step1color={dataAcf.step1color}
            step1Text={dataAcf.step1Text}
            step1Img={dataAcf.step1Img.url}
            step2Title={dataAcf.step2Title}
            step2color={dataAcf.step2color}
            step2Text={dataAcf.step2Text}
            step2Img={dataAcf.step2Img.url}
            step3Title={dataAcf.step3Title}
            step3color={dataAcf.step3color}
            step3Text={dataAcf.step3Text}
            step3Img={dataAcf.step3Img.url}
          />
          <ProgramContainer />
        </PageBaseLayout>
      </Fragment>
    );
  }
}
export default Program;
