import React, { Component } from 'react';
import {
  PageBaseLayout,
  PageDescription,
} from '../..';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import { buttonText } from '../../../constants/buttonText';
import { HashLinkHandle } from '../../../utils/HashLinkHandle';
import {
  BreadTreeElement,
  getData,
} from '../../../utils/PageApi';
import color from '../../colors';
import CompetitivePricingSection from './CompetitivePricingSection';
import FlowSection from './FlowSection';
import FormSection from './FormSection';
import HiringSection from './HiringSection';
import ServiceSection from './ServiceSection';
import type { PageData } from './types';
import './WebDevelopment.css';

type OwnProps = {}

type OwnState = {
  data: PageData;
  breadTreeElements: BreadTreeElement[];
}

function getInitialState() {
  return {
    data: {
      title: { rendered: '' },
      link: '',
      featured_media: 0,
      acf: {
        metaDescription: '',
        heroImg: '',
        subtitle: '',
        pageDescription: '',
        formId: '',
      },
      slug: '',
      parent: 0,
    },
    breadTreeElements: [],
  };
}

const pageId = 3788; // TODO: must refactoring

class WebDevelopment extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = getInitialState();
  }

  async componentDidMount() {
    const state = await getData<PageData>(pageId);

    this.setState(state);
    HashLinkHandle(this.props);
  }

  render() {
    const { data, breadTreeElements } = this.state;
    const { acf: dataAcf, featured_media, link } = data;
    let title = data.title.rendered;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription || ''}
        BreadTreeElements={breadTreeElements}
        heroImgURL={dataAcf.heroImg || ''}
        title={title}
        subTitle={dataAcf.subtitle || ''}
        eyeCatchId={featured_media}
        ogLink={link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={color.lightGray}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
        pageName="web-development"
      >
        <PageDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: dataAcf.pageDescription || '',
            }}
          />
        </PageDescription>
        <FlowSection data={dataAcf} />
        <ServiceSection data={dataAcf} />
        <HiringSection data={dataAcf} />
        <CompetitivePricingSection data={dataAcf} />
        <FormSection data={dataAcf} />
      </PageBaseLayout>
    );
  }
}

export default WebDevelopment;
