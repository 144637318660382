import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../..';
import { media } from '../../../utils/Helper';
import colors from '../../colors';
import { FormDiv } from '../Contact/ContactStyle';
import SendButton from '../Contact/SendButton';
import type {
  PageTemplateCustomFields,
  WebDevelopmentCustomFields,
} from './types';

const FormSectionDiv = styled.div`
  background-color: ${colors.lightGray};
  padding: 84px 0;

  ${media.tablet`padding:84px 2rem 0rem;`}
  .web-development-form-description {
    font-size: 1.2rem;
    display: block;
  }

  select {
    font: -webkit-small-control;
  }

  input {
    width: 100%;
  }

  textarea {
    border: solid 1px gray;
    border-left: solid 2px red;
  }
`;

type OwnProps = {
  data: PageTemplateCustomFields & WebDevelopmentCustomFields;
}

const FormSection = (props: OwnProps) => {
  const { data } = props;
  const { formId } = data;

  return (
    <FormSectionDiv id="webDevForm">
      <Heading2>Contact Form</Heading2>
      <FormDiv dangerouslySetInnerHTML={{ __html: formId || '' }} />
      {formId && <SendButton isEnglishVar={true} />}
    </FormSectionDiv>
  );
};

export default FormSection;
