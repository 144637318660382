async function HashLink(props: any) {
  const pathName = window.location.pathname;
  const hashString = window.location.hash.replace('#', '');
  props.history.push(pathName);
  if (hashString) {
    await setTimeout(function() {
      window.location.hash = hashString;
    }, 100);
  }
}

export function HashLinkHandle(props: any) {
  return HashLink(props);
}
