import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

export const RedirectUrl = (
  <Switch>
    <Redirect from="/about/company/about-us" to="/about/company" />
    <Redirect from="/support/how_to_apply_eta" to="/helpful-info/eta-apply" />
    <Redirect from="/e-transfer" to="/helpful-info/e-transfer" />
    <Redirect from="/strengths/it_web" to="/program/it" />
    <Redirect
      from="/strengths/childcare/ece-license-service"
      to="/program/ECE"
    />
    <Redirect
      from="/strengths/it_web/creative-city-vancouver"
      to="/program/it"
    />
    <Redirect from="/about/partner-schools" to="/school" />
    <Redirect from="/after_support/consultant" to="/program/immigration" />
    <Redirect from="/privacy_policy" to="/privacy-policy" />
    <Redirect
      from="/support/how_to_apply_working_holiday"
      to="/helpful-info/wh-visa-apply"
    />
    <Redirect
      from="/how_to_apply_working_holiday_step2"
      to="/helpful-info/wh-visa-apply2"
    />
    <Redirect from="/strengths/childcare/volunteer" to="/program/ECE" />
    <Redirect from="/strengths/childcare/book_workshop" to="/program/ECE" />
    <Redirect
      from="/strengths/childcare/ece-diploma_certificate"
      to="/program/ECE"
    />
    <Redirect from="/strengths/childcare" to="/program/ECE" />
    <Redirect from="/strengths/childcare/schools" to="/school/ece" />
    <Redirect from="/agreement" to="/terms-of-service" />
    <Redirect
      from="/support/how_to_apply_study_permit"
      to="/helpful-info/student-visa-apply"
    />
    <Redirect from="/strengths" to="/school" />
    <Redirect from="/after_support/pr/work-permit" to="/program/immigration" />
    <Redirect from="/support/insurance" to="/helpful-info/insurance" />
    <Redirect from="/after_support/pr" to="/program/immigration/visa-apply" />
    <Redirect
      from="/after_support/pr/type-of-pr"
      to="/program/immigration/visa-apply"
    />
    <Redirect from="/job_offer" to="/about/job-list" />
    <Redirect from="/ryugaku_flow" to="/support" />
    <Redirect from="/after_support" to="/support" />
  </Switch>
);
