import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Meta from './Meta';

import { media } from '../../utils/Helper';

const LayoutBox = styled.div`
  padding: 0px 8.3rem 1.45rem;
  padding-top: 0;
  ${media.tablet`padding: 55px 1.6rem 0rem;`}
  & main {
    max-width: 1200px;
    margin: auto;
  }
`;

type MetaData = {
  title: string;
  metaDescription: string;
  eyeCatchId: number;
  ogLink: string;
};

interface OwnProps {
  metaData: MetaData;
  pageName?: string;
}
interface OwnState {}

class BasicLayout extends Component<OwnProps, OwnState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Meta metaData={this.props.metaData} />
        <LayoutBox className={this.props.pageName}>
          <main>{this.props.children}</main>
        </LayoutBox>
      </Fragment>
    );
  }
}
export default BasicLayout;
