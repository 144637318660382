import React from 'react';
import styled from 'styled-components';

import { media } from '../../../utils/Helper';
import color from '../../colors';

const CompanyContainter = styled.div`
  width:100%
    ${media.tablet`
  margin-bottom: 3.2rem;
  `}
    a {
    display: inline;
    cursor: pointer;
    color: #fff;
    text-decolation: underline;
  }
  .text-link {
    text-decoration: underline;
  }
  .text-link a:hover {
    color: ${color.red};
  }
  padding-bottom: 2rem;
  padding-top: 2rem;
`;

const TextDiv = styled.div`
  padding: 0.6rem 0;
`;

const FooterCompanyInfo = () => {
  return (
    <CompanyContainter>
      <TextDiv>
        <div>バンクーバオフィス</div>
      </TextDiv>
      <TextDiv>
        <div>Suite 225 – 314 W Cordova St, Vancouver, BC V6B 1E8</div>
      </TextDiv>
      <TextDiv>
        TEL :
        <span className="text-link">
          <a href="tel:+1 (604) 262-6447"> +1 (604) 262-6447</a>
        </span>
      </TextDiv>
      <TextDiv>
        Email :
        <span className="text-link">
          <a href="mailto:info@cosvancouver.com"> info@cosvancouver.com</a>
        </span>
      </TextDiv>
    </CompanyContainter>
  );
};

export default FooterCompanyInfo;
