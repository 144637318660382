import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import Media from 'react-media';
import WebFont from 'webfontloader';
import Header from './Common/Header/header';
import HeaderMobile from './Common/Header/HeaderMobile';
import { BaseContainer } from '../components';
import Footer from './Common/Footer/Footer';

const HeadBox = styled.header`
  position: relative;
`;

class App extends Component {
  componentDidMount() {
    //  Adobe typekit font インストール
    WebFont.load({
      typekit: {
        id: 'gng0obd',
      },
    });
  }
  render() {
    return (
      <BrowserRouter>
        <div id="outer-container" style={{ height: '100%' }}>
          <HeadBox>
            <Media query="(max-width: 1024px)">
              {matches => (matches ? <HeaderMobile /> : <Header />)}
            </Media>
          </HeadBox>
          <main id="page-wrap">
            <BaseContainer />
            <Footer />
          </main>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
