import React, { Component } from 'react';
import styled from 'styled-components';

import {
  PageBaseLayout,
  PageDescription,
  ProgramLogosSection,
  Heading2,
  Heading3,
  ImagePluTextBox,
  MainServicesSection,
  Button,
  LinkHandle,
} from '../..';
import { getData, BreadTreeElement } from '../../../utils/PageApi';
import { getCustomPostApi } from '../../../utils/CustomPostApi';
import { ServiceType } from '../../../type/serviceType';
import cosLogo from '../../../assets/images/COS_Educational_Consulting_Inc_Logo_Jap.svg';
import ECEFromJapanLogoHorizontal from '../../../assets/logo/ECEFromJapanLogoHorizontal.svg';
import { Titles } from '../../../constants/Titles';
import { buttonText } from '../../../constants/buttonText';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import CreateServiceDom from '../../Common/MainServicesSection/CreateServiceDom';
import { media } from '../../../utils/Helper';

const RegisterBox = styled.div`
  width: 50%;
  ${media.tablet`width: 100%;`};
  margin: 0 auto 11.2rem;
`;

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    programTitle: string;
    programText: string;
    programFeatureTitle1: string;
    programFeatureTitle2: string;
    programFeatureTitle3: string;
    programFeatureTitle4: string;
    programFeatureText1: string;
    programFeatureText2: string;
    programFeatureText3: string;
    programFeatureText4: string;
    programFeatureImg1: string;
    programFeatureImg2: string;
    programFeatureImg3: string;
    programFeatureImg4: string;
    serviceButtonText3: string;
  };
  slug: string;
  parent: number;
};

interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
  servicesData: [ServiceType];
}

class Nanny extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          programTitle: '',
          programText: '',
          programFeatureTitle1: '',
          programFeatureTitle2: '',
          programFeatureTitle3: '',
          programFeatureTitle4: '',
          programFeatureText1: '',
          programFeatureText2: '',
          programFeatureText3: '',
          programFeatureText4: '',
          programFeatureImg1: '',
          programFeatureImg2: '',
          programFeatureImg3: '',
          programFeatureImg4: '',
          serviceButtonText3: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
      servicesData: [
        {
          service_category: [0],
          acf: {
            serviceTitle: '',
            serviceText: '',
            serviceImg: { url: '' },
            serviceButtonText: '',
            serviceButtonUrl: '',
            service_order: '',
          },
        },
      ],
    };
  }

  async createData() {
    let pageId = 435;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }

  async createCustomPostData() {
    let SERVICE_CATEGORY_NUM = 9;
    const state = await getCustomPostApi(
      'services',
      'service_category',
      SERVICE_CATEGORY_NUM,
    );
    this.setState({ servicesData: state });
  }

  componentDidMount() {
    this.createData();
    this.createCustomPostData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;
    let servicesData = this.state.servicesData;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={'#fff'}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <PageDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: dataAcf.pageDescription,
            }}
          />
        </PageDescription>
        <ProgramLogosSection
          logo1={cosLogo}
          logo2={ECEFromJapanLogoHorizontal}
        />
        <Heading2>{dataAcf.programTitle}</Heading2>
        <div dangerouslySetInnerHTML={{ __html: dataAcf.programText }} />
        <ImagePluTextBox
          imgURL={dataAcf.programFeatureImg1}
          alt={dataAcf.programFeatureTitle1}
          isImgRightSide={true}
          classname={null}
        >
          <Heading3>{dataAcf.programFeatureTitle1}</Heading3>
          <div
            dangerouslySetInnerHTML={{ __html: dataAcf.programFeatureText1 }}
          />
        </ImagePluTextBox>
        <ImagePluTextBox
          imgURL={dataAcf.programFeatureImg2}
          alt={dataAcf.programFeatureTitle2}
          isImgRightSide={false}
          classname={null}
        >
          <Heading3>{dataAcf.programFeatureTitle3}</Heading3>
          <div
            dangerouslySetInnerHTML={{ __html: dataAcf.programFeatureText2 }}
          />
        </ImagePluTextBox>
        <ImagePluTextBox
          imgURL={dataAcf.programFeatureImg3}
          alt={dataAcf.programFeatureTitle3}
          isImgRightSide={true}
          classname={null}
        >
          <Heading3>{dataAcf.programFeatureTitle3}</Heading3>
          <div
            dangerouslySetInnerHTML={{ __html: dataAcf.programFeatureText3 }}
          />
        </ImagePluTextBox>
        <ImagePluTextBox
          imgURL={dataAcf.programFeatureImg4}
          alt={dataAcf.programFeatureTitle4}
          isImgRightSide={false}
          classname={null}
        >
          <Heading3>{dataAcf.programFeatureTitle4}</Heading3>
          <div
            dangerouslySetInnerHTML={{ __html: dataAcf.programFeatureText4 }}
          />
        </ImagePluTextBox>
        <RegisterBox>
          <Button>
            <LinkHandle to={'https://ecefromjapan.com/childcare-worker-signup'}>
              まずは無料登録
            </LinkHandle>
          </Button>
        </RegisterBox>

        <MainServicesSection h2={Titles.mainService}>
          <CreateServiceDom servicesData={servicesData} />
        </MainServicesSection>
      </PageBaseLayout>
    );
  }
}
export default Nanny;
